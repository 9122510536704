import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Выбор условий",
    component: () => import("@/views/PaymentTerms.vue"),
    meta: { title: "+7Pay | Выбор условий", layout: "Default" },
  },
  {
    path: "/login",
    name: "Вход",
    component: () => import("@/views/Login.vue"),
    meta: { title: "+7Pay | Вход", layout: "Empty" },
  },
  {
    path: "/payment-method",
    name: "Способ оплаты",
    component: () => import("@/views/PaymentMethod.vue"),
    meta: { title: "+7Pay | Способ оплаты", layout: "Default" },
  },
  {
    path: "/payment-code",
    name: "Подтверждение",
    component: () => import("@/views/PaymentCode.vue"),
    meta: { title: "+7Pay | Подтверждение", layout: "Default" },
  },
  {
    path: "/success-installment",
    name: "Рассрочка успешно оформлена",
    component: () => import("@/views/SuccessInstallment.vue"),
    meta: { title: "+7Pay | Рассрочка успешно оформлена", layout: "Default" },
  },
  {
    path: "/personal-data-variant",
    name: "Способ предоставления данных",
    component: () => import("@/views/PersonalDataVariant.vue"),
    meta: { title: "+7Pay | Способ предоставления данных", layout: "Default" },
  },
  {
    path: "/personal-data",
    name: "Редактирование персональных данных",
    component: () => import("@/views/PersonalData.vue"),
    meta: {
      title: "+7Pay | Редактирование персональных данных",
      layout: "Default",
    },
  },
  {
    path: "/payments/return",
    name: "Подтверждение оплаты",
    component: () => import("@/views/PaymentsReturn.vue"),
    meta: { title: "+7Pay | Подтверждение", layout: "Default" },
  },
  {
    path: "/tokens/return",
    redirect: "/payments/return",
    name: "Подтверждение сбп",
    meta: { title: "+7Pay | Подтверждение сбп", layout: "Default" },
  },
  {
    path: "/error-link",
    name: "Некорректная ссылка",
    component: () => import("@/views/ErrorLink.vue"),
    meta: { title: "+7Pay | Что-то пошло не так", layout: "Empty" },
  },
  {
    path: "/payment-arrears",
    name: "Задолженность",
    component: () => import("@/views/PaymentArrears.vue"),
    meta: { title: "+7Pay | Задолженность", layout: "Default" },
  },
  {
    path: "/confirm-phone",
    name: "Подтверждение номера телефона",
    component: () => import("@/views/ConfirmPhone.vue"),
    meta: { title: "+7Pay | Подтверждение номера телефона", layout: "Empty" },
  },
  {
    path: "/esia/error",
    name: "Ошибка идентификации через Госуслуги",
    component: () => import("@/views/EsiaError.vue"),
    meta: {
      title: "+7Pay | Ошибка идентификации через Госуслуги",
      layout: "Empty",
    },
  },
  {
    path: "/esia/success",
    redirect: "/",
    name: "Успешная идентификация через Госуслуги",
    meta: {
      title: "+7Pay | Успешная идентификация через Госуслуги",
      layout: "Empty",
    },
  },
  {
    path: "*",
    redirect: "/error-link",
  },
  {
    path: "/policy",
    name: "Политика обработки данных",
    component: () => import("@/views/Policy.vue"),
    meta: {
      title: "ЛК +7Pay | Политика обработки данных",
      layout: "Empty",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    document.body.scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  if (Object.keys(to.meta).length !== 0) {
    document.title = to.meta.title;
  } else {
    document.title = "+7Pay";
  }
  next();
});

export default router;
